@import "~bootstrap";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css";

@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

body {

    @media screen and (max-width: 578px) {
        > .container {
            padding: unset;

            .row {

            }

            .card {
                .card-body {
                    padding: 0.75rem;
                }

                label {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    margin: unset;

                    &.custom-control-label, &[for="children"] {
                        overflow: unset;
                        white-space: normal;
                    }

                    &[for$="[date_of_birth]"] {
                        max-width: 29vw;
                    }
                }
            }
        }
    }

}
